@import url('http://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.mySlides {display: none}
img {vertical-align: middle;}

.showOnHover {
  display: none;
}

.hoverShow:hover .showOnHover {
  display: block;
}

@media (max-width: 600px) {
  .showOnHover {
    display: block;
  }
}
/* Slideshow container */
.slideshow-container {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.862);
  z-index: 100;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(170, 170, 170, 0.95);
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .1} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}

#play-corner-small { display: none; }

@media (max-width: 600px) {
    #play-corner-small { display: inline-block; }
    #play-corner-big { display: none; }
}

.design-wrapper {
  text-align: center;
  position: absolute;
  padding: 10px;
  vertical-align: middle;
  align-items: center;
  width: 100%;
}

.w3-image2 {
  background-size: cover;
  background-repeat: no-repeat;
  /*background: linear-gradient(rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.35)), url('http://www.threeleaves.org/images/olivetree.jpg');*/
  background-image: url("./images/greentrees.jpg");
  background-attachment: fixed;
  background-position: center;
  padding: 0;
  margin: 0;
}

.image-wrap2 {
  display: flex;
  align-items: flex-end;
  height: 100%;
  bottom: 0;
}

textarea {
  resize: none;
}

#target {
  position: sticky;
  z-index: 110;
}

#overlay {
  position: fixed; /* Sit on top of the page content */ 
  width: 100%; /* Full width (cover the whole page) */
  height: 1000%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0); /* Black background with opacity */
  z-index: 150; /* Specify a stack order in case you're using a different order for other elements */
  animation-fill-mode: forwards;
  pointer-events: none;
}

#overlay2 {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0); /* Black background with opacity */
  z-index: 150; /* Specify a stack order in case you're using a different order for other elements */
  pointer-events: none;
}


.company-logo {
  content:url(./images/logonew2.png);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45em;
}

@media (max-width: 900px) {
  .company-logo {
    width: 20em;
  }
}

.play-btn {
  transform:scale(2.5);
}
@media (max-width: 800px) {
  .play-btn {
    transform:scale(1.3);
  }
}


#footer-play-btn {
  display: inline-block;
}
@media (max-width: 800px) {
  #footer-play-btn {
    display: none;
  }
}

.describe-text {
  font-size: 25px; 
  padding-left: 6%;
  padding-right: 6%;
  z-index: 100;
  color: #FFFFFF;
  font-weight: bold;
  position: absolute;
  width: 100%;
  transform:translateY(100px);

  text-shadow: 2px 8px 6px rgba(0,0,0,0.9), 0px -5px 35px rgba(255,255,255,0.7);
}
@media (max-width: 800px) {
  .describe-text {
    font-size: 14px; 
    padding-left: 2%;
    padding-right: 2%;
  }
}


#navbar-logo { 
  content:url(./images/logo.png);
  width: auto;
  height: 50px;
  top: 2px;
  left: 2px;
  position: absolute;
}

.navbar {
  position:fixed;
  width:100%;
  z-index:120;
  top: 0;
}

.header-parent {
  position: relative;
}

.bgimg-1 {
  background-size:contain;
  right: 0;
  background-repeat: no-repeat;
  background-image: url("./images/bg2.png");
  background-position: center;
  height: 512px;
  margin-bottom: 0;
}

.about-bg {
  background-size: cover;
  background-repeat: no-repeat;
  /*background: linear-gradient(rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.35)), url('http://www.threeleaves.org/images/olivetree.jpg');*/
  background-image: url("./images/screen_bg.jpg");
  background-attachment: fixed;
  background-position: center;
}

@media (max-width: 600px) {
  .bgimg-1 {
    height: 512px;
  }
}

#youtube-trailer {
  width: 75%;
  height: 800px;
}

@media (max-width: 600px) {
  #youtube-trailer {
    width: 98%;
    height: 400px;
  }
}

.title-font {
  font-family: "Poppins", sans-serif;
  font-size: 220px;
  letter-spacing: -10px;
  word-spacing: 15px;
  color: #FFFFFF;
}

.title-font-navbar {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0 0 10px #FFFFFF;
}

.w3-display-title {
  position:absolute;
  width: 100%;
  top:50%;
  right:50%;
  transform:translate(50%,-78%);
  -ms-transform:translate(50%,-78%);
  text-align: center;
}

.header-content-wrapper{
  transform: translateY(35%);
  vertical-align: middle;
}

.header-undertitle {
  color:rgb(255, 255, 255);
  font-size: 50px;
  font-weight: bolder;
  text-shadow: -1px -1px 1px rgba(255,255,255,.5), 1px 1px 1px rgba(0,0,0,.9);
}

.button-wrap {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.header-button {
  width: auto;
  height: 45px;
  padding: 0px 25px 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2.0px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 45px;
  background-color: #efefef;
  box-shadow: 0px 8px 15px rgba(172, 172, 172, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.header-button:hover {
  background-color: #fff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
  color: rgb(0, 0, 0);
  transform: translateY(-5px);
  font-weight: bolder;
}

.icon-container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  transform:translate(0%,50%);
  -ms-transform:translate(0%,50%);
}

.icon-outer-container {
  width: 200px;
  text-align: center;
}

.icon-inner-container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.icon-inner-container > i {
  cursor: pointer;
}

.divider {
  height: 40px;
}

.input2 {
  height: 200px;
}

.lang-btn {
  text-align: right;
  float: right;
  left: inherit;
  right: 0;
}

.lang-options {
  padding: 10%;
  text-align: center;
}

.w3-bar .w3-button {
  padding: 16px;
}

.theme-color {
  color: white;
}

.theme-color-bg {
  color: white;
  background-color: white;
}

.gradient-fade-right {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0) 25%, transparent 82%);
}

.gradient-fade-down {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}

.gradient-fade-up2 {
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 75%, transparent 100%);
}
.gradient-fade-up3 {
  mask-image: 
  linear-gradient(to top, rgba(0, 0, 0, 1.0) 75%, transparent 100%),
  linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 0%, transparent 25%);
}
.gradient-fade-test {
  mask: 
  linear-gradient(to top,  transparent 0%, #000000 0% 75%, transparent 95%),
  linear-gradient(to bottom, transparent 0%, #000000 0% 85%, transparent 95%);
  mask-size: 110% 110%;
  mask-position: center;
  mask-repeat:no-repeat;
  mask-composite: intersect;
  z-index: -100;
}
.gradient-fade-up {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgb(165, 176, 162));
}

.grady {
  background-image: linear-gradient(to right, #4b573f, white, #4b573f); 
}

.no-highlight {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

#main-logo{
  content:url(./images/logo.png);
  min-width: 300px;
  max-width: 300px;
  width: 300px;
  transform: translateY(115%);
}

#main-logo2{
  /*content:url(./images/logo.png);*/
  position: absolute;
  min-width: 150px;
  max-width: 150px;
  width: 150px;
  transform: translate(-50%, -160%);
}

@media (max-width: 600px) {
  #main-logo{
    min-width: 200px;
    max-width: 200px;
    width: 200px;
    transform: translateY(150%);
  }
}

.flip-style {
  animation: flip 0.4s linear;
}

.flip-style2 {
  animation: flip2 0.4s linear;
}

@keyframes flip{
  from{
   transform: rotateY(0deg);
 }
  to{
    transform: rotateY(180deg);
 }
}

@keyframes flip2{
  from{
   transform: rotateY(180deg);
 }
  to{
    transform: rotateY(360deg);
 }
}


#slideshow-box-container {
  height:100%;
  width: 100%;
  position: relative;
}

#slideshow-box-center {
  margin: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

@media (max-width: 1180px) {
  #slideshow-box-center {
    left: 2%;
    right: 2%;
  }
}
@media (max-height: 900px) {
  #slideshow-box-center {
    top: 10%;
  }
}

#slideshow-box {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 122; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.slideshow-box-padding {
  padding: 1% 1%;
  text-align: center;
}

@media (max-width: 1024px) {
  .slideshow-box-padding {
    padding: 10% 5%;
    text-align: center;
  }
}



.card-container {
  position: relative;
}
.bottom-card {
  position: absolute;
  background-color: white;
  pointer-events: none;
  padding-bottom: 16px;
  width: 520px;
  height: 320px;
}
.top-card {
  position: absolute;
  background-color: white;
  z-index: 1;
  padding-bottom: 16px;
  width: 520px;
  height: 320px;
}


@media (max-width: 600px) {
  .bottom-card  {
    width: 100%;
  }
  .top-card  {
    width: 100%;
  }
}

.sticky {
  position: sticky;
  z-index: 50;
  bottom: -625px;
}
@media (max-width: 900px) {
  .sticky {
    bottom: -528px; 
  }
}

#contact-container {
  height:500px;
  position: relative;
}

#contact-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 30%;
  right: 30%;
}

@media (max-width: 1180px) {
  #contact-center {
    left: 2%;
    right: 2%;
  }
}
@media (max-height: 900px) {
  #contact-center {
    top: 10%;
  }
}

#contact {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 122; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.contact-padding {
  padding: 1% 1%;
  text-align: center;
}

@media (max-width: 1024px) {
  .contact-padding {
    padding: 10% 5%;
    text-align: center;
  }
}

#product-wrapper {
  transform: translateY(0px);
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
}
.product-container > div {
  background-color: #f1f1f1;
  width: 520px;
  margin: 210px 15px;
  text-align: center;
  cursor: pointer;
}

.olive-wood-text {
  transform: scale(4.2);
  opacity: 50%;
  filter: brightness(0) invert(1);
}

.olive-text {
  font-family: 'Fira Sans', sans-serif;
  font-size: 28px;
  overflow: auto;
  word-wrap: break-word;
  clear:both;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.shadow-parent {
  position:absolute;
  max-width:85%;
  max-height:30%;
  top:50%;
  left:29%;
  overflow:visible;
}
.shadow-child {
  position:relative;
  max-width:100%;
  max-height:100%;
  margin-top:-20%;
  margin-left:-50%;
}

@media (max-width: 400px) {
  .product-image {
    transform: translateY(50px);
  }
  .product-image .showOnHover {
    transform: translateY(75px);
    bottom: 0px;
  }
}

.product-image {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  /*max-width: 512px;
  max-height: 320px;
  min-height: 300px;*/
}

.product-image2 {
  width: 100%;
  height: 100%;
  margin-right: 20%;
  margin-bottom: 40%;
}

.product-title {
  z-index: 100;
  position: absolute;
  word-break:keep-all;
  line-height: normal;
  font-size: 20px;
  top: 20px;
  text-align: center;
  width: 100%;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.product-title2 {
  position: absolute;
  word-break:keep-all;
  line-height: normal;
  font-size: 14px;
  bottom: 0px;
  text-align: center;
  width: 92%;
  font-weight: lighter;
}

.olive-title {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 40px;
}

.card-title {
  font-size: 16px;
  margin-top: 25px;
  font-weight: bold;
}

.cardback-content {
  line-height: normal;
  font-size: 14px;
  text-align: left;
}

@media (max-width: 400px) {
  .cardback-content {
    font-size: 11px;
  }
  .card-title {
    font-size: 13px;
    margin-top: 20px;
    font-weight: bold;
  }
}
body {
  background-color : #000000;
  margin: 0;
}

li a {text-decoration : none; color : #2d2f31;}

#category-nav {
  transform: translateY(215px);
  margin-bottom: 0;
}

nav {
  width : 300px; 
  margin : 0px auto; 
}

.category-dropdown {
  padding : 5px;
  background : #4b573f; 
  color : white;
  font-size : 1.2em;
  font-variant : small-caps;
  cursor : pointer;
  display: block;
}

.category-dropdown::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear:both;
  background: #d9d9d9;
  width:100%;
  height:0px;
  overflow: hidden;
  text-align: left;
  transition: height .4s ease;  
}

.slide li {padding : 10px;}

#touch {position: absolute; opacity: 0; height: 0px;}    

#touch:checked + .slide {height: 215px;} 

.slide li:hover {
  background:#000000;
}


/* Centered child element can be any width and height */
.centeredchild{
  position: relative; /* position element to participate in z-indexing */
  z-index: 20; /* higher z-index than overlay */
  display: inline-block;
  vertical-align: middle;
  width: 80%; /* can be any width */
}

/* Video container to maintain Youtube 16:9 aspect ratio */
.videowrapper{
  position: relative;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

/* Make Youtube IFRAME responsive */
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


#home-youtube-copy{
  color: #f6921e;
}

.home-play-btn{
  padding-top: 1em;
  color: #FFFFFF;
}

.home-play-btn:hover{
  color: #f6921e;
}
